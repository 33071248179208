// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import './Read.css';

// // const Read = () => {
// //   const [users, setUsers] = useState([]);
// //   const [emails, setEmail] = useState([]);
// //   const [phoneNumbers, setPhoneNumbers] = useState([]);


// //   useEffect(() => {
// //     axios.get('http://localhost:5000/users')
// //       .then(response => {
// //         setUsers(response.data);
// //         const emailList = response.data.map(user => user.email);
// //         setEmail(emailList);
// //         const phoneList = response.data.map(user => user.phone).filter(phone => phone);
// //         setPhoneNumbers(phoneList);
        
// //       })
// //       .catch(error => {
// //         console.error('There was an error fetching the users!', error);
// //       });
// //   }, []);

// //   const mailtoLink = `mailto:${emails.join(',')}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`;

// //   const bulkWhatsAppMessage = `https://wa.me/?text=${encodeURIComponent("Hello everyone!")}`;

// //   const copyToClipboard = () => {
// //     const text = phoneNumbers.map(phone => `https://wa.me/${phone}?text=Hello`).join('\n');
// //     navigator.clipboard.writeText(text);
// //     alert('WhatsApp links copied to clipboard!');
  
// //   };

// //   return (
    
// //      <div>
// //          <h2>Users List</h2>
// //          <table border={1}>
// //           <tr>
// //             <th>Id</th>
// //             <th>Name</th>
// //             <th>Email</th>
// //             <th>Phone</th>
// //             <th>Image</th>
// //             <th>WhatsApp</th>
// //             <th>Mail</th>
// //             <th>Update</th>
// //             <th>Delete</th>
// //           </tr>
// //           {users.map(user => (

// //           <tr li key={user.id}>
// //             <td>{user.id}</td>
// //             <td>{user.name}</td>
// //             <td>{user.email}</td>
// //             <td>{user.phone}</td>
// //             <td>{user.image && <img src={`http://localhost:5000/uploads/${user.image}`} alt={user.name} width="100" />}</td>
// //             <td className='button-column1'>{user.phone && <a href={`https://wa.me/+${user.phone}?text=Hello`}><button>Phone</button></a>}</td>
// //             <td className='button-column'>{user.email &&  <a  href={`mailto:${user.email}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`}><button>Mail</button></a>}</td>
// //             <td className='button-column1'>{user.phone && <a href=''><button>Update</button></a>}</td>
// //             <td className='button-column1'>{user.phone && <a href=''><button>Delete</button></a>}</td>
// //           </tr>
// //           ))}
// //          </table>
// //          {/* <ul>
// //            {users.map(user => (
// //              <li key={user.id}>

// //                {user.id} {user.name} ({user.email}) {user.phone}
// //                {user.image && <img src={`http://localhost:5000/uploads/${user.image}`} alt={user.name} width="100" />}
// //                {user.phone && <a href={`https://wa.me/+${user.phone}?text=Hello`}>Phone</a>}
// //                {user.email && <a href={`mailto:${user.email}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`}>Mail</a>}
// //              </li>
             
// //            ))}
// //          </ul> */}
// //          <a href={mailtoLink}>Bulk Mail Send</a>

// //          <button onClick={copyToClipboard}>Copy WhatsApp Links to Clipboard</button>
// // {/* 
// //          {users.map(user => user.phone && (
// //           <div key={user.id}>
// //             <a href={`https://wa.me/${user.phone}?text=Hello%20${user.name}`} target="_blank" rel="noopener noreferrer">
// //               Send WhatsApp to {user.name}
// //             </a>
// //           </div>
// //         ))} */}
// //        </div>
// //   );
// // };

// // export default Read;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Read.css';

// const Read = () => {
//   const [users, setUsers] = useState([]);
//   const [emails, setEmail] = useState([]);
//   const [phoneNumbers, setPhoneNumbers] = useState([]);
//   const [searchId, setSearchId] = useState('');
//   const [updateUser, setUpdateUser] = useState({});

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = () => {
//     axios.get('http://localhost:5000/users')
//       .then(response => {
//         setUsers(response.data);
//         const emailList = response.data.map(user => user.email);
//         setEmail(emailList);
//         const phoneList = response.data.map(user => user.phone).filter(phone => phone);
//         setPhoneNumbers(phoneList);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the users!', error);
//       });
//   };

//   const handleDelete = (id) => {
//     axios.delete(`http://localhost:5000/user/${id}`)
//       .then(response => {
//         console.log(response.data);
//         fetchUsers(); // Refresh the list after deleting
//       })
//       .catch(error => {
//         console.error('There was an error deleting the user!', error);
//       });
//   };

//   const handleUpdateChange = (e) => {
//     setUpdateUser({ ...updateUser, [e.target.name]: e.target.value });
//   };

//   const handleUpdate = (id) => {
//     axios.put(`http://localhost:5000/user/${id}`, updateUser)
//       .then(response => {
//         console.log(response.data);
//         fetchUsers(); // Refresh the list after updating
//       })
//       .catch(error => {
//         console.error('There was an error updating the user!', error);
//       });
//   };

//   const handleSearch = () => {
//     axios.get(`http://localhost:5000/user/${searchId}`)
//       .then(response => {
//         setUsers([response.data]); // Show only the searched user
//       })
//       .catch(error => {
//         console.error('There was an error searching the user!', error);
//       });
//   };

//   const mailtoLink = `mailto:${emails.join(',')}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`;

//   const copyToClipboard = () => {
//     const text = phoneNumbers.map(phone => `https://wa.me/${phone}?text=Hello`).join('\n');
//     navigator.clipboard.writeText(text);
//     alert('WhatsApp links copied to clipboard!');
//   };

//   return (
//     <div className="read-container">
//       <h2>Users List</h2>
//       <div className="search-bar">
//         <input
//           type="text"
//           placeholder="Search by ID"
//           value={searchId}
//           onChange={(e) => setSearchId(e.target.value)}
//         />
//         <button onClick={handleSearch}>Search</button>
//       </div>
//       <table className="user-table">
//         <thead>
//           <tr>
//             <th>Id</th>
//             <th>Name</th>
//             <th>Email</th>
//             <th>Phone</th>
//             <th>Image</th>
//             <th>WhatsApp</th>
//             <th>Mail</th>
//             <th>Update</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map(user => (
//             <tr key={user.id}>
//               <td>{user.id}</td>
//               <td>
//                 <input
//                   type="text"
//                   name="name"
//                   defaultValue={user.name}
//                   onChange={handleUpdateChange}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   name="email"
//                   defaultValue={user.email}
//                   onChange={handleUpdateChange}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   name="phone"
//                   defaultValue={user.phone}
//                   onChange={handleUpdateChange}
//                 />
//               </td>
//               <td>{user.image && <img src={`http://localhost:5000/uploads/${user.image}`} alt={user.name} width="100" />}</td>
//               <td>{user.phone && <a href={`https://wa.me/+${user.phone}?text=Hello`}><button>Phone</button></a>}</td>
//               <td>{user.email && <a href={`mailto:${user.email}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`}><button>Mail</button></a>}</td>
//               <td><button onClick={() => handleUpdate(user.id)}>Update</button></td>
//               <td><button onClick={() => handleDelete(user.id)}>Delete</button></td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="bulk-actions">
//         <a href={mailtoLink}>Bulk Mail Send</a>
//         <button onClick={copyToClipboard}>Copy WhatsApp Links to Clipboard</button>
//       </div>
//     </div>
//   );
// };

// export default Read;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Read.css';

const Read = () => {
  const [users, setUsers] = useState([]);
  const [emails, setEmail] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [updateUser, setUpdateUser] = useState({});
  const [selectedMessage, setSelectedMessage] = useState('Hello');
  const [selectedPhone, setSelectedPhone] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get('http://localhost:5000/users')
      .then(response => {
        setUsers(response.data);
        const emailList = response.data.map(user => user.email);
        setEmail(emailList);
        const phoneList = response.data.map(user => user.phone).filter(phone => phone);
        setPhoneNumbers(phoneList);
      })
      .catch(error => {
        console.error('There was an error fetching the users!', error);
      });
  };

  const handleDelete = (id) => {
    axios.delete(`http://localhost:5000/user/${id}`)
      .then(response => {
        console.log(response.data);
        fetchUsers(); // Refresh the list after deleting
      })
      .catch(error => {
        console.error('There was an error deleting the user!', error);
      });
  };

  const handleUpdateChange = (e) => {
    setUpdateUser({ ...updateUser, [e.target.name]: e.target.value });
  };

  const handleUpdate = (id) => {
    axios.put(`http://localhost:5000/user/${id}`, updateUser)
      .then(response => {
        console.log(response.data);
        fetchUsers(); // Refresh the list after updating
      })
      .catch(error => {
        console.error('There was an error updating the user!', error);
      });
  };

  const handleSearch = () => {
    axios.get(`http://localhost:5000/user/${searchId}`)
      .then(response => {
        setUsers([response.data]); // Show only the searched user
      })
      .catch(error => {
        console.error('There was an error searching the user!', error);
      });
  };

  const mailtoLink = `mailto:${emails.join(',')}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`;

  const copyToClipboard = () => {
    const text = phoneNumbers.map(phone => `https://wa.me/${phone}?text=Hello`).join('\n');
    navigator.clipboard.writeText(text);
    alert('WhatsApp links copied to clipboard!');
  };

  const handleMessageSelect = (e) => {
    const value = e.target.value;
    if (value === 'custom') {
      sendCustomMessage();
    } else {
      setSelectedMessage(value);
    }
  };

  const handlePhoneClick = (phone) => {
    setSelectedPhone(phone);
    setSelectedMessage('Hello'); // Reset to default message
  };

  const sendMessage = () => {
    if (selectedPhone) {
      const encodedMessage = encodeURIComponent(selectedMessage);
      const whatsappUrl = `https://wa.me/${selectedPhone}?text=${encodedMessage}`;
      window.open(whatsappUrl, '_blank');
    }
  };

  const sendCustomMessage = () => {
    if (selectedPhone) {
      const whatsappUrl = `https://wa.me/${selectedPhone}`;
      window.open(whatsappUrl, '_blank');
    }
  };

  return (
    <div className="read-container">
      <h2>Users List</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          className='search-input'
        />
        <button className='search-button' onClick={handleSearch}>Search</button>
      </div>
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Image</th>
              <th>WhatsApp</th>
              <th>Mail</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  <input
                    type="text"
                    name="name"
                    defaultValue={user.name}
                    onChange={handleUpdateChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="email"
                    defaultValue={user.email}
                    onChange={handleUpdateChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="phone"
                    defaultValue={user.phone}
                    onChange={handleUpdateChange}
                  />
                </td>
                <td>{user.image && <img src={`http://localhost:5000/uploads/${user.image}`} alt={user.name} width="100" />}</td>
                <td>
                  {user.phone && 
                    <button onClick={() => handlePhoneClick(user.phone)}>Phone</button>
                  }
                </td>
                <td>
                  {user.email && 
                    <a href={`mailto:${user.email}?subject=Meeting%20Reminder&body=Don%27t%20forget%20our%20meeting%20at%203%20PM.`}>
                      <button>Mail</button>
                    </a>
                  }
                </td>
                <td>
                  <button onClick={() => handleUpdate(user.id)}>Update</button>
                </td>
                <td>
                  <button onClick={() => handleDelete(user.id)}>Delete</button>
                </td>
                <td>
                  <a href='https://x.com/glovish_t?t=FzAjYovrW3XIGCdJ9xJoZQ&s=09'>X</a>
                  {/* 
https://x.com/glovish_t?t=FzAjYovrW3XIGCdJ9xJoZQ&s=09 */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bulk-actions">
        <a href={mailtoLink}>Bulk Mail Send</a>
        <button onClick={copyToClipboard}>Copy WhatsApp Links to Clipboard</button>
      </div>

      {selectedPhone && (
        <div className="message-selector">
          <label htmlFor="messageSelect">Select a message:</label>
          <select id="messageSelect" value={selectedMessage} onChange={handleMessageSelect}>
            <option value="Hello">Hello</option>
            <option value="How are you?">How are you?</option>
            <option value="Your order is ready.">Your order is ready.</option>
            <option value="custom">Write a new message</option>
          </select>
          {selectedMessage !== 'custom' && (
            <button onClick={sendMessage}>Send Message</button>
          )}
        </div>
      )}
    </div>
  );
};

export default Read;
