import React from 'react';

const Mailto = () => {
  // Define email addresses, subject, and body
  const emails = [
    'otpjava7@gmail.com',
    'megharaju14799@gmail.com',
    'megharaju.official@gmail.com'
  ];
  const subject = 'Your Subject Here';
  const body = 'Your email body here.';

  // Construct the mailto link
  const mailtoLink = `mailto:${emails.join(',')}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <div>
      <a href={mailtoLink}>Send Email</a>
    </div>
  );
};

export default Mailto;
