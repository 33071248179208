import React from 'react';
import './Features.css';

function Features() {
  return (
    <div id="features" className="features-section">
      <div className="features-content">
        <h2>Our Features</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Feature 1</h3>
            <p>Detailed description of Feature 1.</p>
          </div>
          <div className="feature-card">
            <h3>Feature 2</h3>
            <p>Detailed description of Feature 2.</p>
          </div>
          <div className="feature-card">
            <h3>Feature 3</h3>
            <p>Detailed description of Feature 3.</p>
          </div>
          <div className="feature-card">
            <h3>Feature 4</h3>
            <p>Detailed description of Feature 4.</p>
          </div>
          <div className="feature-card">
            <h3>Feature 5</h3>
            <p>Detailed description of Feature 4.</p>
          </div>
          <div className="feature-card">
            <h3>Feature 6</h3>
            <p>Detailed description of Feature 4.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
