import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Create from './components/Create';
import Read from './components/Read';
import Mailto from './components/Mailto';
import Register from './components/Register';
import Home from './components/Home';
import Form from './components/Userpage/Form';
import Sign from './components/Registerpage/Sign';
import IframeComponent from './components/IframeComponent';



import './App.css';
import logo from './logo.svg';
import Message from './components/Message';
import Chat from './components/Chat';
import Twitter from './components/Twitter';
import ProfilePage from './ProfilePage'


function App() {
    return (
    <Router>
      <Twitter/>
      <Routes>
        <Route path="/" element={<Sign />} />
        <Route path="/home" element={<Home />} />
        <Route path="/read" element={<Read />} />
        <Route path="/create" element={<Create />} />
        <Route path="/mail" element={<Mailto />} />
        <Route path="/register" element={<Register />} />
        <Route path="/form" element={<Form />} />
        <Route path="/iframe" element={<IframeComponent />} />
        <Route path="/chat" element={<Chat/>}/>
        <Route path="/:phone" element={<ProfilePage/>}/>
        {/*  path="/:phone" */}
        
      </Routes>

    
    </Router>
  );
}

export default App;
