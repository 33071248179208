// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const login = (userEmail) => {
    setEmail(userEmail);
    localStorage.setItem('email', userEmail);
  };

  const logout = () => {
    setEmail(null);
    localStorage.removeItem('email');
  };

  return (
    <AuthContext.Provider value={{ email, setEmail, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
