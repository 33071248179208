// // src/Chat.js

// import React, { useState } from 'react';
// import axios from 'axios';

// function Chat() {
//   const [input, setInput] = useState('');
//   const [response, setResponse] = useState('');

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const result = await axios.post('http://localhost:5000/chat', { prompt: input });
//     setResponse(result.data);
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={input}
//           onChange={(e) => setInput(e.target.value)}
//         />
//         <button type="submit">Chat</button>
//       </form>
//       <p>{response}</p>
//     </div>
//   );
// }

// export default Chat;


import React, { useState } from 'react';
import axios from 'axios';

function Chat() {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await axios.post('http://localhost:5000/chat', { prompt: input });
      setResponse(result.data);
    } catch (error) {
      console.error("There was an error with the request:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit">Chat</button>
      </form>
      <p>{response}</p>
    </div>
  );
}

export default Chat;
