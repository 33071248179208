// import React, { useState } from 'react';
// import './Sign.css';
// import SignUpModal from './SignUpModal';
// import { useNavigate } from 'react-router-dom';

// const Sign = () => {
//   const [isSignUpOpen, setIsSignUpOpen] = useState(false);
//   const history = useNavigate();

//   const toggleSignUpModal = () => {
//     setIsSignUpOpen(!isSignUpOpen);
//   };

//   const handleSignIn = () => {
//     // Perform sign-in logic here
//     history.push('/nextpage'); // Redirect to the next page after sign-in
//   };

//   return (
//     <>
//       <header className="header">
//         <div className="logo">
//           <img src="https://via.placeholder.com/150x50" alt="Logo" />
//         </div>
//         <div className="auth-buttons">
//           <button className="sign-in" onClick={handleSignIn}>Sign In</button>
//           <button className="sign-up" onClick={toggleSignUpModal}>Sign Up</button>
//         </div>
//       </header>
//       {isSignUpOpen && <SignUpModal onClose={toggleSignUpModal} />}
//     </>
//   );
// };

// export default Sign;

import React, { useState } from 'react';
import './Sign.css'; // Use the same CSS file for consistency
import SignUpModal from './SignUpModal';
import Form from '../Userpage/Form';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Sign = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSignUpModal = () => {
    setIsSignUpOpen(!isSignUpOpen);
  };

  const handleSignUp = () => {
    // Perform sign-in logic here
    navigate('/form'); // Redirect to the next page after sign-in
  };

  return (
    <>
      <div className="Intro">
        <header className="Intro-header">
          <div className="Intro-logo">Company Name</div>
          <nav>
            <ul className="Intro-nav">
              <li>
                <button className="Intro-button" onClick={toggleSignUpModal}>Sign In</button>
              </li>
              <li>
                <button className="Intro-button" onClick={handleSignUp}>Sign Up</button>
              </li>
            </ul>
          </nav>
        </header>
        <main className="Intro-main">
          <div className="Intro-banner">
            <h1>Welcome to our website</h1>
            <p>Your elegant introduction goes here.</p>
            <a href="/form" className="Intro-link-button">Next Page</a>
          </div>
        </main>
      </div>
      {isSignUpOpen && <SignUpModal onClose={toggleSignUpModal} />}
    </>
  );
};

export default Sign;
