// import React, { useState } from 'react';
// import './Form.css';

// const Form = () => {
//   const [step, setStep] = useState(1);
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     image: null,
//     signature: null,
//     address: '',
//     website: '',
//     instagram: '',
//     youtube: '',
//     twitter: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setFormData({ ...formData, [name]: files[0] });
//   };

//   const nextStep = () => {
//     setStep(step + 1);
//   };

//   const prevStep = () => {
//     setStep(step - 1);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Submit form data to the server or handle as needed
//     console.log(formData);
//   };

//   return (
//     <div className="form-container">
//       <form onSubmit={handleSubmit}>
//         {step === 1 && (
//           <div className="form-section">
//             <h2>Personal Information</h2>
//             <div className="form-group">
//               <label htmlFor="name">Name</label>
//               <input type="text" id="name" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="phone">Phone Number</label>
//               <input type="tel" id="phone" name="phone" placeholder="Enter your phone number" value={formData.phone} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="image">Upload Image</label>
//               <input type="file" id="image" name="image" onChange={handleFileChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="signature">Upload Signature</label>
//               <input type="file" id="signature" name="signature" onChange={handleFileChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="address">Address</label>
//               <textarea id="address" name="address" placeholder="Enter your address" value={formData.address} onChange={handleChange} required></textarea>
//             </div>
//             <div className="form-group">
//               <button type="button" onClick={nextStep}>Next</button>
//             </div>
//           </div>
//         )}

//         {step === 2 && (
//           <div className="form-section">
//             <h2>Social Links</h2>
//             <div className="form-group">
//               <label htmlFor="website">Website Link</label>
//               <input type="url" id="website" name="website" placeholder="Enter your website link" value={formData.website} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="instagram">Instagram</label>
//               <input type="url" id="instagram" name="instagram" placeholder="Enter your Instagram link" value={formData.instagram} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="youtube">YouTube</label>
//               <input type="url" id="youtube" name="youtube" placeholder="Enter your YouTube link" value={formData.youtube} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="twitter">Twitter</label>
//               <input type="url" id="twitter" name="twitter" placeholder="Enter your Twitter link" value={formData.twitter} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <button type="button" onClick={prevStep}>Previous</button>
//               <button type="submit">Submit</button>
//             </div>
//           </div>
//         )}
//       </form>
//     </div>
//   );
// };

// export default Form;




// import React, { useState } from 'react';
// import axios from 'axios';
// import './Form.css';
// import { useNavigate } from 'react-router-dom';


// const Form = () => {
//   const [step, setStep] = useState(1);
//   const [formData, setFormData] = useState({
//     name: '',
//     phone: '',
//     image: null,
//     signature: null,
//     address: '',
//     website: '',
//     instagram: '',
//     youtube: '',
//     twitter: '',
//     email:''
  
//   });
//   const navigate = useNavigate();
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setFormData({ ...formData, [name]: files[0] });
//   };

//   const nextStep = () => {
//     setStep(step + 1);
//   };

//   const prevStep = () => {
//     setStep(step - 1);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formDataToSubmit = new FormData();
//     formDataToSubmit.append('name', formData.name);
//     formDataToSubmit.append('phone', formData.phone);
//     formDataToSubmit.append('address', formData.address);
//     formDataToSubmit.append('website', formData.website);
//     formDataToSubmit.append('instagram', formData.instagram);
//     formDataToSubmit.append('youtube', formData.youtube);
//     formDataToSubmit.append('twitter', formData.twitter);
//     formDataToSubmit.append('email', formData.email);
//     if (formData.image) {
//       formDataToSubmit.append('image', formData.image);
//     }
//     if (formData.signature) {
//       formDataToSubmit.append('signature', formData.signature);
//     }

//     try {
      
//       const response = await axios.post('http://localhost:5000/userregister', formDataToSubmit, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log('Form submitted successfully', response.data);
//       navigate('/home')
//     } catch (error) {
//       console.error('Error submitting form', error);
//     }
//   };

//   return (
//     <div className="form-container">
//       <form onSubmit={handleSubmit}>
//         {step === 1 && (
//           <div className="form-section">
//             <h2>Personal Information</h2>
//             <div className="form-group">
//               <label htmlFor="name">Name</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 placeholder="Enter your name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="phone">Phone Number</label>
//               <input
//                 type="tel"
//                 id="phone"
//                 name="phone"
//                 placeholder="Enter your phone number"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label htmlFor="email">Email</label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 placeholder="Enter your Email id"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label htmlFor="image">Upload Image</label>
//               <input type="file" id="image" name="image" onChange={handleFileChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="signature">Upload Signature</label>
//               <input type="file" id="signature" name="signature" onChange={handleFileChange} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="address">Address</label>
//               <textarea
//                 id="address"
//                 name="address"
//                 placeholder="Enter your address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 required
//               ></textarea>
//             </div>
//             <div className="form-group">
//               <button type="button" onClick={nextStep}>
//                 Next
//               </button>
//             </div>
//           </div>
//         )}

//         {step === 2 && (
//           <div className="form-section">
//             <h2>Social Links</h2>
//             <div className="form-group">
//               <label htmlFor="website">Website Link</label>
//               <input
//                 type="url"
//                 id="website"
//                 name="website"
//                 placeholder="Enter your website link"
//                 value={formData.website}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="instagram">Instagram</label>
//               <input
//                 type="url"
//                 id="instagram"
//                 name="instagram"
//                 placeholder="Enter your Instagram link"
//                 value={formData.instagram}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="youtube">YouTube</label>
//               <input
//                 type="url"
//                 id="youtube"
//                 name="youtube"
//                 placeholder="Enter your YouTube link"
//                 value={formData.youtube}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="twitter">Twitter</label>
//               <input
//                 type="url"
//                 id="twitter"
//                 name="twitter"
//                 placeholder="Enter your Twitter link"
//                 value={formData.twitter}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <button type="button" onClick={prevStep}>
//                 Previous
//               </button>
//               <button type="submit">Submit</button>
//             </div>
//           </div>
//         )}
//       </form>
//     </div>
//   );
// };

// export default Form;


import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Form.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';

const Form = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    image: null,
    signature: null,
    address: '',
    website: '',
    instagram: '',
    youtube: '',
    twitter: '',
    email: ''
  });
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 500, height: 200 });
  const sigPad = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth < 600 ? window.innerWidth * 0.8 : 500;
      setCanvasDimensions({ width, height: width / 2.5 });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('phone', formData.phone);
    formDataToSubmit.append('address', formData.address);
    formDataToSubmit.append('website', formData.website);
    formDataToSubmit.append('instagram', formData.instagram);
    formDataToSubmit.append('youtube', formData.youtube);
    formDataToSubmit.append('twitter', formData.twitter);
    formDataToSubmit.append('email', formData.email);
    if (formData.image) {
      formDataToSubmit.append('image', formData.image);
    }
    if (formData.signature) {
      const signatureBlob = dataURLToBlob(formData.signature);
      // edit
      formDataToSubmit.append('signature', signatureBlob, 'signature.png');
    }

    try {
      const response = await axios.post('http://localhost:5000/userregister', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Form submitted successfully', response.data);
      navigate('/');
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const openSignatureModal = () => {
    setIsSignatureModalOpen(true);
  };

  const closeSignatureModal = () => {
    setIsSignatureModalOpen(false);
  };

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const saveSignature = () => {
    if (!sigPad.current.isEmpty()) {
      const signature = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
      setFormData({ ...formData, signature: signature });
      setSignatureData(signature);
      closeSignatureModal();
    } else {
      alert('Please provide a signature.');
    }
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="form-section">
            <h2>Personal Information</h2>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email id"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="image">Upload Image</label>
              <input type="file" id="image" name="image" onChange={handleFileChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="signature">Upload Signature</label>
              <button type="button" onClick={openSignatureModal} className="signature-button">Add Signature</button>
              {signatureData && <img src={signatureData} alt="Signature Preview" />}
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <textarea
                id="address"
                name="address"
                placeholder="Enter your address"
                value={formData.address}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <button type="button" onClick={nextStep}>
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="form-section">
            <h2>Social Links</h2>
            <div className="form-group">
              <label htmlFor="website">Website Link</label>
              <input
                type="url"
                id="website"
                name="website"
                placeholder="Enter your website link"
                value={formData.website}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="instagram">Instagram</label>
              <input
                type="url"
                id="instagram"
                name="instagram"
                placeholder="Enter your Instagram link"
                value={formData.instagram}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="youtube">YouTube</label>
              <input
                type="url"
                id="youtube"
                name="youtube"
                placeholder="Enter your YouTube link"
                value={formData.youtube}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="twitter">Twitter</label>
              <input
                type="url"
                id="twitter"
                name="twitter"
                placeholder="Enter your Twitter link"
                value={formData.twitter}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <button type="button" onClick={prevStep}>
                Previous
              </button>
              <button type="submit">Submit</button>
            </div>
          </div>
        )}
      </form>

      <Modal
        isOpen={isSignatureModalOpen}
        onRequestClose={closeSignatureModal}
        contentLabel="Upload Signature"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Signature</h2>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: canvasDimensions.width, height: canvasDimensions.height, className: 'sigCanvas' }}
          ref={sigPad}
        />
        <button onClick={clearSignature}>Clear</button>
        <button onClick={saveSignature}>Save</button>
        <button onClick={closeSignatureModal}>Cancel</button>
      </Modal>
    </div>
  );
};

export default Form;

