import React, { useState, useEffect } from 'react'
import  './Home.css';
import Header from './Landingpage/Header.js';
import Hero from './Landingpage/Hero.js';
import Actions from './Landingpage/Actions.js';
import Features from './Landingpage/Features.js';
import Contact from './Landingpage/Contact.js';
import Footer from './Landingpage/Footer.js';
import About from './Landingpage/About.js';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
const Home=() =>{
    const { email } = useAuth();
    const [error, setError] = useState('');

    const [data, setProfileData] = useState({
        name: '',
        email: '',
        phone: '',
        image: null,
        address: '',
        website: '',
        instagram: '',
        youtube: '',
        twitter: ''
      });

      useEffect(() => {
        if (email) {
          fetchProfileData();
        }
      }, [email]);

      const fetchProfileData = async () => {
        try {
          const response = await axios.post('http://127.0.0.1:5000/user_profile', { email });
          setProfileData(response.data);
    
          
        } catch (error) {
          console.error('Failed to fetch profile data:', error);
          setError('Failed to fetch profile data. Please try again.');
        }
      };
    

    return(
    //     <div className="Hcontainer">
    //     {/* <h1>Welcome to Our Site</h1> */}
    //     <div className="button-container">
    //         <a href="/register" className="animated-button">Register</a>
    //         <a href="/create" className="animated-button">Scan</a>
    //     </div>
    // </div>
    <div>
        <div>hi {data.name}</div>
        <Header />
        <Hero />
        <Actions />
        <About />
        <Features />
        <Contact />
        <Footer /> 
        <li><Link to="/iframe">Iframe</Link></li>
        <li><Link to={`/${data.phone}`}>profile</Link></li>
    </div>
    );

};

export default Home;

