import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ProfilePage = () => {
  const { phone } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`http://127.0.0.1:5000/${phone}`);
        setProfileData(response.data);
      } catch (error) {
        console.error('Failed to fetch profile data:', error);
        setError('Failed to fetch profile data. Please try again.');
      }
    };

    if (phone) {
      fetchProfileData();
    }
  }, [phone]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!profileData) {
    return <div>Loading...</div>;
  }


  const shareProfileLink = (phone) => {
    // http://localhost:3000/9876543210
    // https://instaconnect.in/9886413886
    const profileLink = `http://localhost:3000/${phone}`;
    navigator.clipboard.writeText(profileLink).then(() => {
      alert('Profile link copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy profile link: ', err);
    });
  };

  return (
    <div className="profile-page">
      <h1>Profile</h1>
      <img src={profileData.image ? `http://localhost:5000/uploads/${profileData.image}` : 'https://via.placeholder.com/50'} alt='profile' />
      <p><strong>Name:</strong> {profileData.name}</p>
      <p><strong>Email:</strong> {profileData.email}</p>
      <p><strong>Phone:</strong> {profileData.phone}</p>
      <p><strong>Address:</strong> {profileData.address}</p>
      <p><strong>Website:</strong> <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
      <p><strong>Instagram:</strong> <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">{profileData.instagram}</a></p>
      <p><strong>YouTube:</strong> <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">{profileData.youtube}</a></p>
      <p><strong>Twitter:</strong> <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">{profileData.twitter}</a></p>
      <button className='form-button button' onClick={() => shareProfileLink(profileData.phone)}>Share Profile</button>
    </div>
  );
};

export default ProfilePage;
