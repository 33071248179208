import React from 'react';
// import heroImage from 'C:/Users/Sushmitha/crud-app/frontend/src/Images/DALL·E 2024-07-13 12.11.27 - A professional and sleek business-themed hero image for a website. The image should include a modern office background with a clean desk, a laptop, an.webp';
import './Hero.css';

function Hero() {
  return (
    // <div id="hero" style={{ backgroundImage: `url(${heroImage})` }}>
    <div id='hero'>
        
      <div className="hero-content">
        <h1>Welcome to Our Business Card Service</h1>
        <p>Your tagline goes here.</p>
        <a href="#about" className="cta-button">Learn More</a>
      </div>
  
    </div>
    
  );
}

export default Hero;
