import React, { useState } from 'react';
import './RegistrationForm.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const Register= () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    profilePicture: '',
    location: ''
  });
  const navigate = useNavigate();

  const handleChange = e => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: files ? files[0] : value
    }));
  };

//const handleSubmit = e => {
//e.preventDefault();
//console.log(formData);

//// Add form submission logic here
//};

const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('image', formData.profilePicture);
    data.append('location', formData.location);
    console.log(data.get("image"));


    try {
        console.log("try")
        const response = await axios.post('http://localhost:5000/register', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log("try end")
        console.log(response.data);
        navigate('/home')

      } catch (error) {
        console.error('There was an error submitting the form!', error);
      }
    };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('name', name);
//     formData.append('email', email);
//     if (image) {
//       formData.append('image', image);
//     }
//     axios.post('http://localhost:5000/user', formData)
//       .then(response => {
//         console.log(response.data);
//         navigate('/')
//       })
//       .catch(error => {
//         console.error('There was an error creating the user!', error);
//       });
//   };

  return (
    <div className="container">
      <div className="form-wrapper">
        <h2 className="title">Register</h2>
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            className="input"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            className="input"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            className="input"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="file"
            name="profilePicture"
            className="file-input"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="location"
            className="input"
            placeholder="Location"
            value={formData.location}
            onChange={handleChange}
            required
          />
          <button type="submit" className="button">Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;