// import React, { useState } from 'react';
// import axios from 'axios';
// import './SignUpModal.css';
// import { useNavigate } from 'react-router-dom';


// const SignUpModal = ({ onClose }) => {
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');
//   const [generatedOtp, setGeneratedOtp] = useState('');
//   const [isOtpSent, setIsOtpSent] = useState(false);

//   const navigate = useNavigate();

//   const handleSendOtp = async () => {
//     try {
//       const response = await axios.post('http://127.0.0.1:5000/send_otp', { email });
//       setGeneratedOtp(response.data.otp);
//       setIsOtpSent(true);
//     } catch (error) {
//       console.error('Failed to send OTP:', error);
//     }
//   };

//   const handleVerifyOtp = () => {
//     if (otp === generatedOtp) {
//       alert('OTP verified successfully!');
//       onClose();
//       navigate('/home')
//     } else {
//       alert('Invalid OTP, please try again.');
//     }
//   };

//   return (
//     <div className="modal">
//       <div className="modal-content">
//         <span className="close" onClick={onClose}>&times;</span>
//         <h2>Sign Up</h2>
//         <input
//           type="email"
//           placeholder="Enter your email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//         {isOtpSent ? (
//           <>
//             <input
//               type="text"
//               placeholder="Enter OTP"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//             />
//             <button onClick={handleVerifyOtp}>Verify OTP</button>
//           </>
//         ) : (
//           <button onClick={handleSendOtp}>Send OTP</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SignUpModal;


// import React, { useState } from 'react';
// import axios from 'axios';
// import './SignUpModal.css';
// import { useNavigate } from 'react-router-dom';

// const SignUpModal = ({ onClose }) => {
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');
//   const [generatedOtp, setGeneratedOtp] = useState('');
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const navigate = useNavigate();

//   const handleSendOtp = async () => {
//     try {
//       const response = await axios.post('http://127.0.0.1:5000/send_otp', { email });
//       setGeneratedOtp(response.data.otp);
//       setIsOtpSent(true);
      
//     } catch (error) {
//       console.error('Failed to send OTP:', error);
//     }
//   };

//   const handleVerifyOtp = () => {
//     if (otp === generatedOtp) {
//       alert('OTP verified successfully!');
//       onClose();
//       navigate('/home');
//     } else {
//       alert('Invalid OTP, please try again.');
//     }
//   };

//   return (
//     <div className="modal">
//       <div className="modal-content">
//         <div className="logo">
//           <img src="https://via.placeholder.com/50" alt="Logo" />
//         </div>
//         {!isOtpSent ? (
//           <>
//             <h2>Log in to Networking Ninja</h2>
//             <p>Please enter your email address</p>
//             <form>
//             <input
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//             <button className="continue-button" onClick={handleSendOtp}>Continue</button>
//             <button className="google-button">Continue with Google</button>
//             </form>
//           </>
//         ) : (
//           <>
//             <h2>Check your email</h2>
//             <p>We've sent a pin to {email}</p>
//             <input
//               type="text"
//               placeholder="00000"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//             />
//             <button className="continue-button" onClick={handleVerifyOtp}>Sign In</button>
//             <button className="resend-button">I need another pin</button>
//           </>
//         )}
//         <span className="close" onClick={onClose}>&times;</span>
//       </div>
//     </div>
//   );
// };

// export default SignUpModal;


// import React, { useState } from 'react';
// import axios from 'axios';
// import './SignUpModal.css';
// import { useNavigate } from 'react-router-dom';

// const SignUpModal = ({ onClose }) => {
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');
//   const [generatedOtp, setGeneratedOtp] = useState('');
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
  

//   const handleSendOtp = async () => {
//     if (!validateEmail(email)) {
//       setError('Please enter a valid email address.');
//       return;
//     }
//     setError('');
//     try {
//       const response = await axios.post('http://127.0.0.1:5000/send_otp', { email });
//       setGeneratedOtp(response.data.otp);
//       setIsOtpSent(true);
//     } catch (error) {
//       console.error('Failed to send OTP:', error);
//       setError('Failed to send OTP. Please try again.');
//     }
//   };

//   const handleVerifyOtp = () => {
//     if (otp === generatedOtp) {
//       alert('OTP verified successfully!');
//       onClose();
//       navigate('/home');
//     } else {
//       alert('Invalid OTP, please try again.');
//     }
//   };

//   const reSendOtp = async () => {
//         try {
//           console.log(email);
//           const response = await axios.post('http://127.0.0.1:5000/send_otp', { email });
//           setGeneratedOtp(response.data.otp);
//           setIsOtpSent(true);
          
//         } catch (error) {
//           console.error('Failed to send OTP:', error);
//         }
//       };
    

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   return (
//     <div className="modal">
//       <div className="modal-content">
//         <div className="logo">
//           <img src="https://via.placeholder.com/50" alt="Logo" />
//         </div>
//         {!isOtpSent ? (
//           <>
//             <h2>Log in</h2>
//             <p>Please enter your email address</p>
//             <input
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               className='input'
//             />
//             {error && <p className="error">{error}</p>}
//             <button
//               className="continue-button button"
//               onClick={handleSendOtp}
//               disabled={!validateEmail(email)}
//             >
//               Continue
//             </button>
//             {/* <button className="google-button button">Continue with Google</button> */}
//           </>
//         ) : (
//           <>
//             <h2>Check your email</h2>
//             <p>We've sent a pin to {email}</p>
//             <input
//               type="text"
//               placeholder="00000"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//               className='input'
//             />
//             <button className="continue-button button" onClick={handleVerifyOtp}>Sign In</button>
//             <button className="resend-button button" onClick={reSendOtp}>I need another pin</button>
//           </>
//         )}
//         <span className="close" onClick={onClose}>&times;</span>
//       </div>
//     </div>
//   );
// };

// export default SignUpModal;

// import React, { useState } from 'react';
// import axios from 'axios';
// import './SignUpModal.css';
// import { useNavigate } from 'react-router-dom';

// const SignUpModal = ({ onClose }) => {
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');
//   const [generatedOtp, setGeneratedOtp] = useState('');
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
  
//   const handleSendOtp = async () => {
//     if (!validateEmail(email)) {
//       setError('Please enter a valid email address.');
//       return;
//     }
//     setError('');
    
//     try {
//       // Check if user exists
//       const checkResponse = await axios.post('http://127.0.0.1:5000/check_user', { email });
//       if (checkResponse.data.exists) {
//         console.log(email)
//         // Send OTP if user exists
//         const otpResponse = await axios.post('http://127.0.0.1:5000/send_otp', { email });
//         setGeneratedOtp(otpResponse.data.otp);
//         console.log(email)
//         setIsOtpSent(true);

//       } else {
//         setError('User does not exist. Please sign up first.');
//       }
//     } catch (error) {
//       console.error('Error checking user existence or sending OTP:', error);
//       setError('An error occurred. Please try again.');
//     }
//   };

//   const handleVerifyOtp = () => {
//     if (otp === generatedOtp) {
//       alert('OTP verified successfully!'+email);

//       onClose();
//       navigate('/home');
//     } else {
//       alert('Invalid OTP, please try again.');
//     }
//   };

//   const reSendOtp = async () => {
//     try {
//       const response = await axios.post('http://127.0.0.1:5000/send_otp', { email });
//       setGeneratedOtp(response.data.otp);
//       setIsOtpSent(true);
//     } catch (error) {
//       console.error('Failed to send OTP:', error);
//       setError('Failed to send OTP. Please try again.');
//     }
//   };

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   return (
//     <div className="modal">
//       <div className="modal-content">
//         <div className="logo">
//           <img src="https://via.placeholder.com/50" alt="Logo" />
//         </div>
//         {!isOtpSent ? (
//           <>
//             <h2>Log in</h2>
//             <p>Please enter your email address</p>
//             <input
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               className='input'
//             />
//             {error && <p className="error">{error}</p>}
//             <button
//               className="continue-button button"
//               onClick={handleSendOtp}
//               disabled={!validateEmail(email)}
//             >
//               Continue
//             </button>
//           </>
//         ) : (
//           <>
//             <h2>Check your email</h2>
//             <p>We've sent a pin to {email}</p>
//             <input
//               type="text"
//               placeholder="00000"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//               className='input'
//             />
//             {error && <p className="error">{error}</p>}
//             <button className="continue-button button" onClick={handleVerifyOtp}>Sign In</button>
//             <button className="resend-button button" onClick={reSendOtp}>I need another pin</button>
//           </>
//         )}
//         <span className="close" onClick={onClose}>&times;</span>
//       </div>
//     </div>
//   );
// };

// export default SignUpModal;
import React, { useState } from 'react';
import axios from 'axios';
import './SignUpModal.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const SignUpModal = ({ onClose }) => {
  const [email, setEmails] = useState('');
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setEmail } = useAuth();  // Directly use the useAuth hook

  const handleSendOtp = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    setError('');
    
    try {
      const checkResponse = await axios.post('http://127.0.0.1:5000/check_user', { email });
      if (checkResponse.data.exists) {
        const otpResponse = await axios.post('http://127.0.0.1:5000/send_otp', { email });
        setGeneratedOtp(otpResponse.data.otp);
        setIsOtpSent(true);
      } else {
        setError('User does not exist. Please sign up first.');
      }
    } catch (error) {
      console.error('Error checking user existence or sending OTP:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const handleVerifyOtp = () => {
    if (otp === generatedOtp) {
      alert('OTP verified successfully! ' + email);
      setEmail(email);  // Set the email in the global context
      onClose();
      navigate('/home');
    } else {
      alert('Invalid OTP, please try again.');
    }
  };

  const reSendOtp = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:5000/send_otp', { email });
      setGeneratedOtp(response.data.otp);
      setIsOtpSent(true);
    } catch (error) {
      console.error('Failed to send OTP:', error);
      setError('Failed to send OTP. Please try again.');
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="logo">
          <img src="https://via.placeholder.com/50" alt="Logo" />
        </div>
        {!isOtpSent ? (
          <>
            <h2>Log in</h2>
            <p>Please enter your email address</p>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmails(e.target.value)}
              required
              className='input'
            />
            {error && <p className="error">{error}</p>}
            <button
              className="continue-button button"
              onClick={handleSendOtp}
              disabled={!validateEmail(email)}
            >
              Continue
            </button>
          </>
        ) : (
          <>
            <h2>Check your email</h2>
            <p>We've sent a pin to {email}</p>
            <input
              type="text"
              placeholder="00000"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              className='input'
            />
            {error && <p className="error">{error}</p>}
            <button className="continue-button button" onClick={handleVerifyOtp}>Sign In</button>
            <button className="resend-button button" onClick={reSendOtp}>I need another pin</button>
          </>
        )}
        <span className="close" onClick={onClose}>&times;</span>
      </div>
    </div>
  );
};

export default SignUpModal;
