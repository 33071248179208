import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import './Create.css';

const Create = () => {
  // const [name, setName] = useState('');
   const [location, setLocation] = useState('');
  const [image, setImage] = useState(null);
  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = (e) => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
    e.preventDefault();
    const formData = new FormData();
    // formData.append('name', name);
     formData.append('location', location);
    if (image && profile) {
      formData.append('image', image);
      formData.append('profile', profile);
    }
    axios.post('http://localhost:5000/upload', formData)
      .then(response => {
        console.log(response.data);
        navigate('/')
      })
      .catch(error => {
        console.error('There was an error creating the user!', error);
      });
  };

  return (
    <div>
      <div class="form-container">
        <h2>Upload Your Business Card Details</h2>
        <form onSubmit={handleSubmit}>
            <div class="form-group">
                <label for="file">Upload File</label>
                <input type="file" onChange={(e) => setImage(e.target.files[0])} required/>
            </div>
            <div class="form-group">
                <label for="file">Upload Profile Picture</label>
                <input type="file" onChange={(e) => setProfile(e.target.files[0])} required/>
            </div>
            <div class="form-group">
                <label for="text">Location</label>
                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required/>
            </div> 
            <div class="form-group">
                <button type="submit">Submit</button>
            </div>
        </form>
    </div>
    {/* <h2>Create User</h2>
    <form onSubmit={handleSubmit}>
      <div>
        <label>Name</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div>
        <label>Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <label>Image</label>
        <input type="file" onChange={(e) => setImage(e.target.files[0])} />
      </div>
      <button type="submit">Create</button>
    </form> */}
  </div>
  );
};

export default Create;