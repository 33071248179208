// import React, { useState } from 'react';
// import './Profilemodal.css';

// const Profilemodal = ({ onClose }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [profileData, setProfileData] = useState({
//     name: 'John Doe',
//     phone: '123-456-7890',
//     image: null,
//     signature: null,
//     address: '123 Main St, City, Country',
//     website: 'https://example.com',
//     instagram: 'https://instagram.com/username',
//     youtube: 'https://youtube.com/username',
//     twitter: 'https://twitter.com/username'
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({ ...profileData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setProfileData({ ...profileData, [name]: files[0] });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission, e.g., save the updated profile data
//     setIsEditing(false);
//   };

//   return (
//     <div className="profile-modal">
//       <div className="profile-modal-content">
//         <button className="close-button" onClick={onClose}>X</button>
//         {isEditing ? (
//           <form onSubmit={handleSubmit}>
//             <div className="form-group">
//               <label>Name</label>
//               <input type="text" name="name" value={profileData.name} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label>Phone</label>
//               <input type="tel" name="phone" value={profileData.phone} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label>Image</label>
//               <input type="file" name="image" onChange={handleFileChange} />
//             </div>
//             <div className="form-group">
//               <label>Signature</label>
//               <input type="file" name="signature" onChange={handleFileChange} />
//             </div>
//             <div className="form-group">
//               <label>Address</label>
//               <textarea name="address" value={profileData.address} onChange={handleChange} required></textarea>
//             </div>
//             <div className="form-group">
//               <label>Website</label>
//               <input type="url" name="website" value={profileData.website} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label>Instagram</label>
//               <input type="url" name="instagram" value={profileData.instagram} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label>YouTube</label>
//               <input type="url" name="youtube" value={profileData.youtube} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label>Twitter</label>
//               <input type="url" name="twitter" value={profileData.twitter} onChange={handleChange} required />
//             </div>
//             <button type="submit">Submit</button>
//           </form>
//         ) : (
//           <div className="profile-details">
//             <p><strong>Name:</strong> {profileData.name}</p>
//             <p><strong>Phone:</strong> {profileData.phone}</p>
//             <p><strong>Address:</strong> {profileData.address}</p>
//             <p><strong>Website:</strong> <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
//             <p><strong>Instagram:</strong> <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">{profileData.instagram}</a></p>
//             <p><strong>YouTube:</strong> <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">{profileData.youtube}</a></p>
//             <p><strong>Twitter:</strong> <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">{profileData.twitter}</a></p>
//             <button onClick={() => setIsEditing(true)}>Edit</button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Profilemodal;



// import React, { useState } from 'react';
// import './Profilemodal.css';
// import { FaUserCircle } from 'react-icons/fa';

// const Profilemodal = ({ onClose }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [profileData, setProfileData] = useState({
//     name: 'John Doe',
//     email: 'john.doe@example.com',
//     phone: '123-456-7890',
//     image: null,
//     address: '123 Main St, City, Country',
//     website: 'https://example.com',
//     instagram: 'https://instagram.com/username',
//     youtube: 'https://youtube.com/username',
//     twitter: 'https://twitter.com/username'
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({ ...profileData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setProfileData({ ...profileData, [name]: files[0] });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission, e.g., save the updated profile data
//     setIsEditing(false);
//   };

//   return (
//     <div className="profile-modal">
//       <div className="profile-modal-content">
//         <button className="close-button" onClick={onClose}>X</button>
//         {isEditing ? (
//           <form onSubmit={handleSubmit}>
//             <div className="form-group">
//               <label className='form-label' style={{color:'#fff'}}>Name</label>
//               <input type="text" name="name" value={profileData.name} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{color:'#fff'}}>Email</label>
//               <input type="email" name="email" value={profileData.email} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{color:'#fff'}}>Phone</label>
//               <input type="tel" name="phone" value={profileData.phone} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{color:'#fff'}}>Image</label>
//               <input type="file" name="image" onChange={handleFileChange} />
//             </div>
//             <div className="form-group">
//               <label style={{color:'#fff'}}>Address</label>
//               <textarea name="address" value={profileData.address} onChange={handleChange} required></textarea>
//             </div>
//             <button className='form-button button' type="submit">Submit</button>
//           </form>
//         ) : (
//           <div className="profile-details">
//             {/* <FaUserCircle size={50} className="profile-image" /> */}
//             <img className='profile-image' src='https://tse2.mm.bing.net/th?id=OIP.H3Xxvz-5yotdS4Xr6SfnmwHaHa&pid=Api&P=0&h=180' alt='profile image'/>
//             <p><strong>Name:</strong> {profileData.name}</p>
//             <p><strong>Email:</strong> {profileData.email}</p>
//             <p><strong>Phone:</strong> {profileData.phone}</p>
//             <p><strong>Address:</strong> {profileData.address}</p>
//             <p><strong>Website:</strong> <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
//             <p><strong>Instagram:</strong> <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">{profileData.instagram}</a></p>
//             <p><strong>YouTube:</strong> <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">{profileData.youtube}</a></p>
//             <p><strong>Twitter:</strong> <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">{profileData.twitter}</a></p>
//             <button className='form-button button' onClick={() => setIsEditing(true)}>Edit</button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Profilemodal;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Profilemodal.css';

// const Profilemodal = ({ onClose, userEmail }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     image: null,
//     address: '',
//     website: '',
//     instagram: '',
//     youtube: '',
//     twitter: ''
//   });
//   const [error, setError] = useState('');

//   useEffect(() => {
//     if (userEmail) {
//       fetchProfileData();
//       console.log(userEmail);
//     }
//   }, [userEmail]);

//   const fetchProfileData = async () => {
//     try {
//       console.log('Fetching profile data for email:', userEmail);
//       const response = await axios.post('http://127.0.0.1:5000/user_profile', { email: userEmail });
//       console.log('Profile data fetched:', response.data);
//       setProfileData(response.data);
//     } catch (error) {
//       console.error('Failed to fetch profile data:', error);
//       setError('Failed to fetch profile data. Please try again.');
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({ ...profileData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setProfileData({ ...profileData, [name]: files[0] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     for (const key in profileData) {
//       formData.append(key, profileData[key]);
//     }
//     try {
//       await axios.put('http://127.0.0.1:5000/update_profile', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       setIsEditing(false);
//       fetchProfileData(); // Refresh the profile data
//     } catch (error) {
//       console.error('Failed to update profile data:', error);
//       setError('Failed to update profile data. Please try again.');
//     }
//   };

//   return (
//     <div className="profile-modal">
//       <div className="profile-modal-content">
//         <button className="close-button" onClick={onClose}>X</button>
//         {isEditing ? (
//           <form onSubmit={handleSubmit}>
//             <div className="form-group">
//               <label className='form-label' style={{ color: '#fff' }}>Name</label>
//               <input type="text" name="name" value={profileData.name} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Email</label>
//               <input type="email" name="email" value={profileData.email} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Phone</label>
//               <input type="tel" name="phone" value={profileData.phone} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Image</label>
//               <input type="file" name="image" onChange={handleFileChange} />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Address</label>
//               <textarea name="address" value={profileData.address} onChange={handleChange} required></textarea>
//             </div>
//             <button className='form-button button' type="submit">Submit</button>
//           </form>
//         ) : (
//           <div className="profile-details">
//             <img className='profile-image' src={profileData.image ? `http://localhost:5000/uploads/${profileData.image}` : 'https://via.placeholder.com/50'} alt='profile' />
//             <p><strong>Name:</strong> {profileData.name}</p>
//             <p><strong>Email:</strong> {profileData.email}</p>
//             <p><strong>Phone:</strong> {profileData.phone}</p>
//             <p><strong>Address:</strong> {profileData.address}</p>
//             <p><strong>Website:</strong> <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
//             <p><strong>Instagram:</strong> <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">{profileData.instagram}</a></p>
//             <p><strong>YouTube:</strong> <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">{profileData.youtube}</a></p>
//             <p><strong>Twitter:</strong> <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">{profileData.twitter}</a></p>
//             <button className='form-button button' onClick={() => setIsEditing(true)}>Edit</button>
//           </div>
//         )}
//         {error && <p className="error">{error}</p>}
//       </div>
//     </div>
//   );
// };

// export default Profilemodal;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Profilemodal.css';

// const Profilemodal = ({ onClose, userEmail }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     image: null,
//     address: '',
//     website: '',
//     instagram: '',
//     youtube: '',
//     twitter: ''
//   });
//   const [error, setError] = useState('');

//   useEffect(() => {
//     if (userEmail) {
//       fetchProfileData();
//     }
//   }, [userEmail]);

//   const fetchProfileData = async () => {
//     try {
//       const response = await axios.post('http://127.0.0.1:5000/user_profile', { email: userEmail });
//       setProfileData(response.data);
//     } catch (error) {
//       console.error('Failed to fetch profile data:', error);
//       setError('Failed to fetch profile data. Please try again.');
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({ ...profileData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     const { name, files } = e.target;
//     setProfileData({ ...profileData, [name]: files[0] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     for (const key in profileData) {
//       formData.append(key, profileData[key]);
//     }
//     try {
//       await axios.put('http://127.0.0.1:5000/update_profile', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       setIsEditing(false);
//       fetchProfileData(); // Refresh the profile data
//     } catch (error) {
//       console.error('Failed to update profile data:', error);
//       setError('Failed to update profile data. Please try again.');
//     }
//   };

//   return (
//     <div className="profile-modal">
//       <div className="profile-modal-content">
//         <button className="close-button" onClick={onClose}>X</button>
//         {isEditing ? (
//           <form onSubmit={handleSubmit}>
//             <div className="form-group">
//               <label className='form-label' style={{ color: '#fff' }}>Name</label>
//               <input type="text" name="name" value={profileData.name} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Email</label>
//               <input type="email" name="email" value={profileData.email} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Phone</label>
//               <input type="tel" name="phone" value={profileData.phone} onChange={handleChange} required />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Image</label>
//               <input type="file" name="image" onChange={handleFileChange} />
//             </div>
//             <div className="form-group">
//               <label style={{ color: '#fff' }}>Address</label>
//               <textarea name="address" value={profileData.address} onChange={handleChange} required></textarea>
//             </div>
//             <button className='form-button button' type="submit">Submit</button>
//           </form>
//         ) : (
//           <div className="profile-details">
//             <img className='profile-image' src={profileData.image ? `http://localhost:5000/uploads/${profileData.image}` : 'https://via.placeholder.com/50'} alt='profile' />
//             <p><strong>Name:</strong> {profileData.name}</p>
//             <p><strong>Email:</strong> {profileData.email}</p>
//             <p><strong>Phone:</strong> {profileData.phone}</p>
//             <p><strong>Address:</strong> {profileData.address}</p>
//             <p><strong>Website:</strong> <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
//             <p><strong>Instagram:</strong> <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">{profileData.instagram}</a></p>
//             <p><strong>YouTube:</strong> <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">{profileData.youtube}</a></p>
//             <p><strong>Twitter:</strong> <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">{profileData.twitter}</a></p>
//             <button className='form-button button' onClick={() => setIsEditing(true)}>Edit</button>
//           </div>
//         )}
//         {error && <p className="error">{error}</p>}
//       </div>
//     </div>
//   );
// };

// export default Profilemodal;



// src/components/Profilemodal.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profilemodal.css';
import { useAuth } from '../../context/AuthContext';

const Profilemodal = ({ onClose }) => {
  const { email } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    phone: '',
    image: null,
    address: '',
    website: '',
    instagram: '',
    youtube: '',
    twitter: ''
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (email) {
      fetchProfileData();
    }
  }, [email]);

  const fetchProfileData = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:5000/user_profile', { email });
      setProfileData(response.data);

      
    } catch (error) {
      console.error('Failed to fetch profile data:', error);
      setError('Failed to fetch profile data. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setProfileData({ ...profileData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in profileData) {
      formData.append(key, profileData[key]);
    }
    try {
      await axios.put('http://127.0.0.1:5000/update_profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setIsEditing(false);
      fetchProfileData(); // Refresh the profile data
    } catch (error) {
      console.error('Failed to update profile data:', error);
      setError('Failed to update profile data. Please try again.');
    }
  };

  return (
    <div className="profile-modal">
      <div className="profile-modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className='form-label' style={{ color: '#fff' }}>Name</label>
              <input type="text" name="name" value={profileData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label style={{ color: '#fff' }}>Email</label>
              <input type="email" name="email" value={profileData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label style={{ color: '#fff' }}>Phone</label>
              <input type="tel" name="phone" value={profileData.phone} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label style={{ color: '#fff' }}>Image</label>
              <input type="file" name="image" onChange={handleFileChange} />
            </div>
            <div className="form-group">
              <label style={{ color: '#fff' }}>Address</label>
              <textarea name="address" value={profileData.address} onChange={handleChange} required></textarea>
            </div>
            <button className='form-button button' type="submit">Submit</button>
          </form>
        ) : (
          <div className="profile-details">
            <img className='profile-image' src={profileData.image ? `http://localhost:5000/uploads/${profileData.image}` : 'https://via.placeholder.com/50'} alt='profile' />
            <p><strong>Name:</strong> {profileData.name}</p>
            <p><strong>Email:</strong> {profileData.email}</p>
            <p><strong>Phone:</strong> {profileData.phone}</p>
            <p><strong>Address:</strong> {profileData.address}</p>
            <p><strong>Website:</strong> <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
            <p><strong>Instagram:</strong> <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">{profileData.instagram}</a></p>
            <p><strong>YouTube:</strong> <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">{profileData.youtube}</a></p>
            <p><strong>Twitter:</strong> <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">{profileData.twitter}</a></p>
            <button className='form-button button' onClick={() => setIsEditing(true)}>Edit</button>
          </div>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Profilemodal;
