
import React from 'react';

const IframeComponent = () => {
  return (
    <iframe
      src="https://www.chatbase.co/chatbot-iframe/OtHNpcYNEAoeqgtkHxQpG"
      width="100%"
      style={{ height: '100%', minHeight: '700px' }}
      frameBorder="0"
    ></iframe>
  );
};

export default IframeComponent;
