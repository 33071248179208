import React from 'react';
import './Actions.css';

function Actions() {
  return (
    <div id="actions" className="actions-section">
      <h2>Get Started</h2>
      <div className="actions-buttons">
      <a className='action-button-a'  href=''> <button className="action-button scan">Scan Business Card</button></a>
        <a className='action-button-a'  href='/create'><button className="action-button upload">Upload Form</button></a>
        <a className='action-button-a' href='/register'><button className="action-button manual">Manual Entry</button></a>
      </div>
    </div>
  );
}

export default Actions;
