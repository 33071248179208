import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Twitter.css';

function Twitter() {
  const [tweetLink, setTweetLink] = useState('');

  useEffect(() => {
    axios.get('http://localhost:5000/api/tweet-link')
      .then(response => {
        setTweetLink(response.data.tweetLink);
      })
      .catch(error => {
        console.error("There was an error fetching the tweet link!", error);
      });
  }, []);

  return (
    <div className="App">
      <h1>Direct Twitter Link</h1>
      {tweetLink && (
        <a href={tweetLink} target="_blank" rel="noopener noreferrer">
          Tweet
        </a>
      )}
    </div>
  );
}

export default Twitter;
