// import React, { useState } from 'react';
// import './Header.css';
// import Profilemodal from './Profilemodal';
// import { FaUserCircle } from 'react-icons/fa';

// const Header = () => {
//   const [isProfileOpen, setIsProfileOpen] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const toggleProfileModal = () => {
//     setIsProfileOpen(!isProfileOpen);
//   };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   return (
//     <header className="header">
//       <div className="navbar">
//         <div className="logo">Your Company</div>
//         <nav className={isMobileMenuOpen ? 'nav-mobile' : 'nav-desktop'}>
//           <ul className="nav-links">
//             <li><a href="#hero">Home</a></li>
//             <li><a href="#about">About</a></li>
//             <li><a href="#features">Features</a></li>
//             <li><a href="#contact">Contact</a></li>
//           </ul>
//         </nav>
//         <div className="icon-container">
//           <FaUserCircle size={30} className="profile-icon" onClick={toggleProfileModal} />
//           <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
//             <span className="menu-bar"></span>
//             <span className="menu-bar"></span>
//             <span className="menu-bar"></span>
//           </div>
//         </div>
//       </div>
//       {isProfileOpen && <Profilemodal onClose={toggleProfileModal} />}
//     </header>
//   );
// };

// export default Header;

// import React, { useState } from 'react';
// import './Header.css';
// import { FaUserCircle } from 'react-icons/fa';
// import Profilemodal from './Profilemodal';


// const Header = ({ userEmail }) => {
//   const [isProfileOpen, setIsProfileOpen] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const toggleProfileModal = () => {
//     setIsProfileOpen(!isProfileOpen);
//   };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   return (
//     <header className="header">
//       <div className="navbar">
//         <div className="logo">Your {userEmail}</div>
//         <nav className={isMobileMenuOpen ? 'nav-mobile' : 'nav-desktop'}>
//           <ul className="nav-links">
//             <li><a href="#hero">Home</a></li>
//             <li><a href="#about">About</a></li>
//             <li><a href="#features">Features</a></li>
//             <li><a href="#contact">Contact</a></li>
//           </ul>
//         </nav>
//         <div className="icon-container">
//           <FaUserCircle size={30} className="profile-icon" onClick={toggleProfileModal} />
//           <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
//             <span className="menu-bar"></span>
//             <span className="menu-bar"></span>
//             <span className="menu-bar"></span>
//           </div>
//         </div>
//       </div>
//       {isProfileOpen && <Profilemodal onClose={toggleProfileModal} userEmail={userEmail} />}
//     </header>
//   );
// };

// export default Header;

// src/components/Header.js
import React, { useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import Profilemodal from './Profilemodal';
// import { useAuth } from '../context/AuthContext';
import './Header.css';
import { useAuth } from '../../context/AuthContext';

const Header = () => {
  const { email, logout } = useAuth();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleProfileModal = () => {
    
    setIsProfileOpen(!isProfileOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <div className="navbar">
        <div className="logo">Your Company</div>
        <nav className={isMobileMenuOpen ? 'nav-mobile' : 'nav-desktop'}>
          <ul className="nav-links">
            <li><a href="#hero">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <div className="icon-container">
          <FaUserCircle size={30} className="profile-icon" onClick={toggleProfileModal} />
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <span className="menu-bar"></span>
            <span className="menu-bar"></span>
            <span className="menu-bar"></span>
          </div>
          <button onClick={logout}>Logout</button>
        </div>
      </div>
      {isProfileOpen && <Profilemodal onClose={toggleProfileModal} userEmail={email} />}
    </header>
  );
};

export default Header;

