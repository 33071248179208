import React from 'react';
import './About.css';

function About() {
  return (
    <div id="about" className="about-section">
      <div className="about-content">
        <h2>About Us</h2>
        <p>
          We are dedicated to providing the best business card services. Our team of experts ensures that you get the most efficient and user-friendly experience possible.
        </p>
        <p>
          Our mission is to help you manage and share your business information seamlessly. Whether you need to scan, upload, or manually enter your business details, we have got you covered.
        </p>
      </div>
    </div>
  );
}

export default About;
